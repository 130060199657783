import React, { useState } from "react";
import axios from "axios"; // Import axios
import { forgotPassword, confirmPassword } from "../../authContext/CognitoAuth";
import "./ForgotPassword.css";
import { BiLoader } from "react-icons/bi";

function ForgotPassword({ setShowLoginPage }) {
  const [username, setUsername] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [stage, setStage] = useState(1);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);

  const checkUserExists = async (username) => {
    try {
      const response = await axios.post('https://9r0yzxc77h.execute-api.ap-southeast-2.amazonaws.com/checkuserexistsinpoolv3/', {
        username,
        userPoolId: 'ap-southeast-2_f4INtknmN'
      });

      const responseData = JSON.parse(response.data.body);
      return responseData.is_user_exists;
    } catch (error) {
      console.error('Error checking user existence:', error);
      throw new Error('Error checking user existence');
    }
  };

  const handleForgotPassword = async () => {
    setLoading(true);
    try {
      const userExists = await checkUserExists(username);
      if (userExists) {
        await forgotPassword(username);
        setStage(2);
        setError("");
      } else {
        setError("User does not exist.");
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmPassword = async () => {
    setLoading(true);
    try {
      await confirmPassword(username, verificationCode, newPassword);
      setSuccess("Password has been reset successfully. Please log in with your new password.");
      setError("");
      setTimeout(() => {
        setShowLoginPage(true);
      }, 4000);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="forgot-password-container-wrapper">
      <div className="forgot-password-container">
        {stage === 1 ? (
          <>
            <h2>Forgot Password</h2>
            <div className="input-group">
              <label htmlFor="username">Username</label>
              <input
                type="text"
                id="username"
                value={username}
                onChange={(e) => {
                  setUsername(e.target.value);
                  setError("");  
                }}
              />
            </div>
            <button className="btn btn-warning" onClick={handleForgotPassword}>
              {loading ? <BiLoader /> : "Submit"}
            </button>
          </>
        ) : (
          <>
            <h2>Reset Password</h2>
            <div className="input-group">
              <label htmlFor="verificationCode">Verification Code</label>
              <input
                type="text"
                id="verificationCode"
                value={verificationCode}
                onChange={(e) => {
                  setVerificationCode(e.target.value);
                  setError("");
                }}
              />
            </div>
            <div className="input-group">
              <label htmlFor="newPassword">New Password</label>
              <input
                type="password"
                id="newPassword"
                value={newPassword}
                onChange={(e) => {
                  setNewPassword(e.target.value);
                  setError("");
                }}
              />
            </div>
            <button className="btn btn-warning" onClick={handleConfirmPassword}>
              {loading ? <BiLoader /> : "Reset Password"}
            </button>
          </>
        )}
        {error && <p className="error-message">{error}</p>}
        {success && <p className="success-message">{success}</p>}
        <p className="forgotswitchLogin" onClick={() => setShowLoginPage(true)}>Back to Login</p>
      </div>
    </div>
  );
}

export default ForgotPassword;

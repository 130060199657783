import React, { useState } from "react";
import "./MainSideBar.css";
import { configData } from "../navbar/config";
import { NavLink, useLocation } from "react-router-dom";
import { CircleImg } from "../../common/assets/svg/CircleImg";
import SidebarLogo from "../../common/assets/images/imdexlogo.png";
import MagicWand from "../../assets/magic-wand-01.png";
import RouteSymbol from "../../assets/route.png";
import Stars from "../../assets/stars-01.png";
import Rocket from "../../assets/rocket-02.png";
import { ArrowDown } from "../../common/assets/svg/ArrowDown";
import { BiLoader } from "react-icons/bi";
import axios from "axios";
import ArrowRight from "../../common/assets/svg/ArrowRight";
import ChatSideBar from "./ChatSideBar";
import { Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import { useAuth } from "../../authContext/AuthContext";

const MainSidebar = ({
  setMode,
  mode,
  selectedCompanion,
  setSelectedCompanionState,
}) => {
  const { userEmail } = useAuth();
  const location = useLocation();
  const isChatAi = ["/chat-ai/", "/chat-ai"].includes(location.pathname);
  const name = userEmail?.split("@")[0];
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const getImageSource = (header) => {
    switch (header) {
      case "Discovery Companion":
        return MagicWand;
      case "Workflow Companion":
        return RouteSymbol;
      case "AI Companion":
        return Stars;
      case "Discover Features":
        return Rocket;
      default:
        return "";
    }
  };

  const handleMode = () => {
    const newMode = mode === "DARK" ? "LIGHT" : "DARK";
    setMode(newMode);
    sessionStorage.setItem("mode", newMode);
  };

  const handleLogoutClick = () => {
    setLoading(true);
    const postapiEndpoint =
      "https://5mct3djcha.execute-api.ap-southeast-2.amazonaws.com/llm_log_out/";
    const accessToken = sessionStorage.getItem("accessToken");
    const postpayload = {
      body: {
        session_token: accessToken,
      },
    };
    axios
      .post(postapiEndpoint, postpayload)
      .then((response) => {
        console.log("logout request successful:", response);
        sessionStorage.clear();
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error in logout request:", error);
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const isSettingsPage = location.pathname === "/powerapp";

  return (
    <div className="main-sidebar w-20 text-white">
      <div>
        <img className="p-3" src={SidebarLogo} alt="sidebarlogo" />
        <ul className="sidebar-companion-toggle-btn">
          {configData.map((route) => {
            const isActive = location.pathname === route.path;
            return (
              <li
                className={isActive ? "active" : ""}
                onClick={() => setSelectedCompanionState(route.header)}
              >
                <div>
                  <span>
                    <img
                      src={getImageSource(route.header)}
                      alt="companionsymbols"
                    />
                  </span>
                  <NavLink to={route.path}>{route.header}</NavLink>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
      {isChatAi && <ChatSideBar />}
      <div>
        <ul className="setting">
          {/* settings disabled for bethesda */}
          {/* <NavLink to="/powerapp" activeClassName="active">
            <li className={isSettingsPage ? "active" : ""}>Settings</li>
          </NavLink> */}
          <li className={isSettingsPage ? "active" : ""}>Settings</li>
          <li>Help</li>
          <li>
            <div className="d-flex align-items-center gap-1">
              <span>{mode === "DARK" ? "Light Mode" : "Dark Mode"}</span>
              <div>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={mode === "DARK"}
                    onChange={handleMode}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
          </li>
          <li className="user-details">
            <span className="user-icon">
              {" "}
              <CircleImg />
            </span>
            <div>
              <div> {name}</div>
              <span className="landingpage-user-email">{userEmail}</span>
              <span className="landingpage-arrow-down">
                <ArrowDown />
                <div className="landingpage-dropdown-content">
                  <div>
                    <span
                      className="landing-logout-btn"
                      onClick={handleLogoutClick}
                    >
                      <ArrowRight />
                      {loading ? <BiLoader /> : "Logout"}
                    </span>
                  </div>
                  <div>
                  <Link to="feedback" onClick={()=>setSelectedCompanionState("Feedback")}>
                    <span>Feedback</span>
                  </Link>
                </div>
                <div>
                  <Link to="user-feedbacks" onClick={()=>setSelectedCompanionState("User Feedback")}>
                    <span>User Feedback</span>
                  </Link>
                </div>
                </div>
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MainSidebar;

import {
    CognitoUserPool,
    CognitoUser,
    AuthenticationDetails,
    CognitoUserAttribute
} from 'amazon-cognito-identity-js';

const poolData = {
    UserPoolId: 'ap-southeast-2_f4INtknmN',  
    ClientId: '5muagmr2i56flhrut5n3kjsi8a'    
};

const userPool = new CognitoUserPool(poolData);

export const authenticate = (username, password) => {
    return new Promise((resolve, reject) => {
        const authenticationDetails = new AuthenticationDetails({
            Username: username,
            Password: password
        });

        const userData = {
            Username: username,
            Pool: userPool 
        };

        const cognitoUser = new CognitoUser(userData);

        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: (result) => {
                
                const tokens = {
                    idToken: result.idToken.jwtToken,
                    accessToken: result.accessToken.jwtToken,
                    refreshToken: result.refreshToken.token,
                  };

                  sessionStorage.setItem('idToken', tokens.idToken);
                  sessionStorage.setItem('accessToken', tokens.accessToken);
                  sessionStorage.setItem('refreshToken', tokens.refreshToken);
                  resolve(tokens);
            },
            onFailure: (err) => {
                reject(err); 
            },
            newPasswordRequired: (userAttributes, requiredAttributes) => {
                // Optionally handle new password requirement
                resolve({ status: "newPasswordRequired", userAttributes, requiredAttributes });
            }
        });
    });
};


export const signUp = (username, password, email, callback) => {
    const attributeList = [];

    const dataEmail = {
        Name: 'email',
        Value: email
    };

    const attributeEmail = new CognitoUserAttribute(dataEmail);
    attributeList.push(attributeEmail);

    userPool.signUp(username, password, attributeList, null, (err, result) => {
        if (err) {
            console.error(err);
            if (callback && typeof callback.onFailure === 'function') {
                callback.onFailure(err);
            }
            return;
        }
        const cognitoUser = result.user;
        console.log('User registration successful:', cognitoUser.getUsername());
        if (callback && typeof callback.onSuccess === 'function') {
            callback.onSuccess(result);
        }
    });
};
export const forgotPassword = (username) => {
    return new Promise((resolve, reject) => {
        const userData = {
            Username: username,
            Pool: userPool
        };

        const cognitoUser = new CognitoUser(userData);

        cognitoUser.forgotPassword({
            onSuccess: (data) => {
                console.log('Password reset initiated successfully:', data);
                resolve(data);
            },
            onFailure: (err) => {
                console.error('Password reset initiation failed:', err);
                reject(err);
            }
        });
    });
};
export const confirmPassword = (username, verificationCode, newPassword) => {
    return new Promise((resolve, reject) => {
        const userData = {
            Username: username,
            Pool: userPool
        };

        const cognitoUser = new CognitoUser(userData);

        cognitoUser.confirmPassword(verificationCode, newPassword, {
            onSuccess: () => {
                console.log('Password has been reset successfully');
                resolve();
            },
            onFailure: (err) => {
                console.error('Password reset failed:', err);
                reject(err);
            }
        });
    });
};

